// Generated by Framer (033a048)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["YLj6bugSg", "lSwB4jM2C"];

const serializationHash = "framer-soyGL"

const variantClassNames = {lSwB4jM2C: "framer-v-1hqaypv", YLj6bugSg: "framer-v-1fn1gsd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "YLj6bugSg", "Variant 2": "lSwB4jM2C"}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, JtQVnp5Wl: title ?? props.JtQVnp5Wl ?? "TITLE", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "YLj6bugSg", XiQImWKLn: link ?? props.XiQImWKLn} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, JtQVnp5Wl, XiQImWKLn, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "YLj6bugSg", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterw8o4bl = activeVariantCallback(async (...args) => {
setVariant("lSwB4jM2C")
})

const onMouseLeave1sw3rgd = activeVariantCallback(async (...args) => {
setVariant("YLj6bugSg")
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "lSwB4jM2C") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={XiQImWKLn} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1fn1gsd", className, classNames)} framer-h1yua8`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"YLj6bugSg"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterw8o4bl} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({lSwB4jM2C: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeave1sw3rgd}}, baseVariant, gestureVariant)}>{isDisplayed() && (<motion.div className={"framer-mk425j"} layoutDependency={layoutDependency} layoutId={"UIe19T8V4"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5}}/>)}<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7QmFybG93LTUwMA==", "--framer-font-family": "\"Barlow\", \"Barlow Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "500", "--framer-line-height": "20px"}}>HOME</motion.p></React.Fragment>} className={"framer-ydehg"} fonts={["GF;Barlow-500"]} layoutDependency={layoutDependency} layoutId={"HFBuRmAig"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={JtQVnp5Wl} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-soyGL.framer-h1yua8, .framer-soyGL .framer-h1yua8 { display: block; }", ".framer-soyGL.framer-1fn1gsd { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: 40px; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-soyGL .framer-mk425j { flex: none; height: 10px; overflow: hidden; position: relative; width: 10px; will-change: var(--framer-will-change-override, transform); }", ".framer-soyGL .framer-ydehg { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-soyGL.framer-1fn1gsd { gap: 0px; } .framer-soyGL.framer-1fn1gsd > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-soyGL.framer-1fn1gsd > :first-child { margin-left: 0px; } .framer-soyGL.framer-1fn1gsd > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"lSwB4jM2C":{"layout":["auto","fixed"]}}}
 * @framerVariables {"JtQVnp5Wl":"title","XiQImWKLn":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framertd7RdX5yy: React.ComponentType<Props> = withCSS(Component, css, "framer-soyGL") as typeof Component;
export default Framertd7RdX5yy;

Framertd7RdX5yy.displayName = "Navitext";

Framertd7RdX5yy.defaultProps = {height: 40, width: 36};

addPropertyControls(Framertd7RdX5yy, {variant: {options: ["YLj6bugSg", "lSwB4jM2C"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, JtQVnp5Wl: {defaultValue: "TITLE", displayTextArea: false, placeholder: "", title: "Title", type: ControlType.String}, XiQImWKLn: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framertd7RdX5yy, [{explicitInter: true, fonts: [{family: "Barlow", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3_-gc5VAtlT47dw.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})